<script>
  import { countries } from './data.js';
  let selectedCountryIndex = Math.floor(Math.random() * (countries.length - 1));
  let enteredValue = '';
  let message = '';
  let guessMode = 'country'; // 'country' or 'capital'

  function checkAnswer() {
    const country = countries[selectedCountryIndex];
    if (guessMode === 'country') {
      if (enteredValue.toLowerCase() === country.translatedName.toLowerCase()) {
        message = "Correct!";
      } else {
        message = "Incorrect! Try again.";
      }
    } else if (guessMode === 'capital') {
      if (enteredValue.toLowerCase() === country.capital.toLowerCase()) {
        message = "Correct!";
      } else {
        message = "Incorrect! Try again.";
      }
    }
  }

  function nextCountry() {
    selectedCountryIndex = Math.floor(Math.random() * (countries.length - 1));
    enteredValue = '';
    message = '';
  }

  function toggleMode() {
    guessMode = guessMode === 'country' ? 'capital' : 'country';
    enteredValue = '';
    message = '';
  }

  function showAnswer() {
    const country = countries[selectedCountryIndex];
    message = guessMode === 'country' ? `Correct answer is ${country.translatedName}`: `Correct answer is ${country.capital}`;
  }
</script>

<main>
  <h1>Country and Capital Quiz</h1>
  <div class="flag-container">
    <img src={countries[selectedCountryIndex].flag} alt="{countries[selectedCountryIndex].translatedName} flag" width="300" height="150" />
  </div>
  <!--<p>Country: {countries[selectedCountryIndex].country}</p>  Display the country name here -->
  <div class="inputs">
    <label for="guess">{guessMode === 'country' ? 'Country:' : 'Capital:'}</label>
    <input type="text" id="guess" bind:value={enteredValue} placeholder={guessMode === 'country' ? 'Enter country name' : 'Enter capital name'} />
  </div>
  <button on:click={checkAnswer}>Check Answer</button>
  <button on:click={showAnswer}>Show Answer</button>
  <button on:click={nextCountry}>Next {guessMode === 'country' ? 'Capital' : 'Country'}</button>
  <button on:click={toggleMode}>Switch to {guessMode === 'country' ? 'Capital' : 'Country'} Mode</button>
  <p>{message}</p>
</main>

<style>
  main {
    text-align: center;
    padding: 2rem;
  }

  .flag-container {
    margin-bottom: 1rem;
  }

  .inputs {
    margin: 0.5rem 0;
  }

  button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
  }
</style>
