export const countries =  [
  {
    country: "Afghanistan",
    translatedName: "Afghanistan",
    capital: "Kabul",
    flag: "./flags/afg.svg"
  },
  {
    country: "Aland Islands",
    translatedName: "Aland",
    capital: "Mariehamn",
    flag: "./flags/ala.svg"
  },
  {
    country: "Albania",
    translatedName: "Albanien",
    capital: "Tirana",
    flag: "./flags/alb.svg"
  },
  {
    country: "Algeria",
    translatedName: "Algerien",
    capital: "Algiers",
    flag: "./flags/dza.svg"
  },
  {
    country: "American Samoa",
    translatedName: "Amerikanisch-Samoa",
    capital: "Pago Pago",
    flag: "./flags/asm.svg"
  },
  {
    country: "Andorra",
    translatedName: "Andorra",
    capital: "Andorra la Vella",
    flag: "./flags/and.svg"
  },
  {
    country: "Angola",
    translatedName: "Angola",
    capital: "Luanda",
    flag: "./flags/ago.svg"
  },
  {
    country: "Anguilla",
    translatedName: "Anguilla",
    capital: "The Valley",
    flag: "./flags/aia.svg"
  },
  {
    country: "Antigua and Barbuda",
    translatedName: "Antigua und Barbuda",
    capital: "Saint John's",
    flag: "./flags/atg.svg"
  },
  {
    country: "Argentina",
    translatedName: "Argentinien",
    capital: "Buenos Aires",
    flag: "./flags/arg.svg"
  },
  {
    country: "Armenia",
    translatedName: "Armenien",
    capital: "Yerevan",
    flag: "./flags/arm.svg"
  },
  {
    country: "Aruba",
    translatedName: "Aruba",
    capital: "Oranjestad",
    flag: "./flags/abw.svg"
  },
  {
    country: "Australia",
    translatedName: "Australien",
    capital: "Canberra",
    flag: "./flags/aus.svg"
  },
  {
    country: "Austria",
    translatedName: "Österreich",
    capital: "Vienna",
    flag: "./flags/aut.svg"
  },
  {
    country: "Azerbaijan",
    translatedName: "Aserbaidschan",
    capital: "Baku",
    flag: "./flags/aze.svg"
  },
  {
    country: "Bahamas",
    translatedName: "Bahamas",
    capital: "Nassau",
    flag: "./flags/bhs.svg"
  },
  {
    country: "Bahrain",
    translatedName: "Bahrain",
    capital: "Manama",
    flag: "./flags/bhr.svg"
  },
  {
    country: "Bangladesh",
    translatedName: "Bangladesch",
    capital: "Dhaka",
    flag: "./flags/bgd.svg"
  },
  {
    country: "Barbados",
    translatedName: "Barbados",
    capital: "Bridgetown",
    flag: "./flags/brb.svg"
  },
  {
    country: "Belarus",
    translatedName: "Weißfrussland",
    capital: "Minsk",
    flag: "./flags/blr.svg"
  },
  {
    country: "Belgium",
    translatedName: "Belgien",
    capital: "Brussels",
    flag: "./flags/bel.svg"
  },
  {
    country: "Belize",
    translatedName: "Belize",
    capital: "Belmopan",
    flag: "./flags/blz.svg"
  },
  {
    country: "Benin",
    translatedName: "Benin",
    capital: "Porto-Novo",
    flag: "./flags/ben.svg"
  },
  {
    country: "Bermuda",
    translatedName: "Bermuda",
    capital: "Hamilton",
    flag: "./flags/bmu.svg"
  },
  {
    country: "Bhutan",
    translatedName: "Bhutan",
    capital: "Thimphu",
    flag: "./flags/btn.svg"
  },
  {
    country: "Bolivia",
    translatedName: "Bolivien",
    capital: "Sucre",
    flag: "./flags/bol.svg"
  },
  {
    country: "Bonaire",
    translatedName: "Bonaire",
    capital: "Kralendijk",
    flag: "./flags/bes.svg"
  },
  {
    country: "Bosnia and Herzegovina",
    translatedName: "Bosnien und Herzegowina",
    capital: "Sarajevo",
    flag: "./flags/bih.svg"
  },
  {
    country: "Botswana",
    translatedName: "Botswana",
    capital: "Gaborone",
    flag: "./flags/bwa.svg"
  },
  {
    country: "Brazil",
    translatedName: "Brasilien",
    capital: "Brasilia",
    flag: "./flags/bra.svg"
  },
  {
    country: "British Indian Ocean Territory",
    translatedName: "Britisches Territorium im Indischen Ozean",
    capital: "Diego Garcia",
    flag: "./flags/iot.svg"
  },
  {
    country: "British Virgin Islands",
    translatedName: "Britische Jungferninseln",
    capital: "Road Town",
    flag: "./flags/vgb.svg"
  },
  {
    country: "Brunei",
    translatedName: "Brunei",
    capital: "Bandar Seri Begawan",
    flag: "./flags/brn.svg"
  },
  {
    country: "Bulgaria",
    translatedName: "Bulgarien",
    capital: "Sofia",
    flag: "./flags/bgr.svg"
  },
  {
    country: "Burkina Faso",
    translatedName: "Burkina Faso",
    capital: "Ouagadougou",
    flag: "./flags/bfa.svg"
  },
  {
    country: "Burundi",
    translatedName: "Burundi",
    capital: "Bujumbura",
    flag: "./flags/bdi.svg"
  },
  {
    country: "Cambodia",
    translatedName: "Kambodscha",
    capital: "Phnom Penh",
    flag: "./flags/khm.svg"
  },
  {
    country: "Cameroon",
    translatedName: "Kamerun",
    capital: "Yaounde",
    flag: "./flags/cmr.svg"
  },
  {
    country: "Canada",
    translatedName: "Kanada",
    capital: "Ottawa",
    flag: "./flags/can.svg"
  },
  {
    country: "Cape Verde",
    translatedName: "Kap Verde",
    capital: "Praia",
    flag: "./flags/cpv.svg"
  },
  {
    country: "Cayman Islands",
    translatedName: "Kaimaninseln",
    capital: "George Town",
    flag: "./flags/cym.svg"
  },
  {
    country: "Central African Republic",
    translatedName: "Zentralafrikanische Republik",
    capital: "Bangui",
    flag: "./flags/caf.svg"
  },
  {
    country: "Chad",
    translatedName: "Tschad",
    capital: "N'Djamena",
    flag: "./flags/tcd.svg"
  },
  {
    country: "Chile",
    translatedName: "Chile",
    capital: "Santiago",
    flag: "./flags/chl.svg"
  },
  {
    country: "China",
    translatedName: "China",
    capital: "Beijing",
    flag: "./flags/chn.svg"
  },
  {
    country: "Christmas Island",
    translatedName: "Weihnachtsinsel",
    capital: "Flying Fish Cove",
    flag: "./flags/cxr.svg"
  },
  {
    country: "Cocos (Keeling) Islands",
    translatedName: "Kokosinseln",
    capital: "West Island",
    flag: "./flags/cck.svg"
  },
  {
    country: "Colombia",
    translatedName: "Kolumbien",
    capital: "Bogota",
    flag: "./flags/col.svg"
  },
  {
    country: "Comoros",
    translatedName: "Union der Komoren",
    capital: "Moroni",
    flag: "./flags/com.svg"
  },
  {
    country: "Republic of the Congo",
    translatedName: "Kongo",
    capital: "Brazzaville",
    flag: "./flags/cog.svg"
  },
  {
    country: "Democratic Republic of the Congo",
    translatedName: "Kongo (Dem. Rep.)",
    capital: "Kinshasa",
    flag: "./flags/cod.svg"
  },
  {
    country: "Cook Islands",
    translatedName: "Cookinseln",
    capital: "Avarua",
    flag: "./flags/cok.svg"
  },
  {
    country: "Costa Rica",
    translatedName: "Costa Rica",
    capital: "San Jose",
    flag: "./flags/cri.svg"
  },
  {
    country: "Croatia",
    translatedName: "Kroatien",
    capital: "Zagreb",
    flag: "./flags/hrv.svg"
  },
  {
    country: "Cuba",
    translatedName: "Kuba",
    capital: "Havana",
    flag: "./flags/cub.svg"
  },
  {
    country: "Curacao",
    translatedName: "Curacao",
    capital: "Willemstad",
    flag: "./flags/cuw.svg"
  },
  {
    country: "Cyprus",
    translatedName: "Zypern",
    capital: "Nicosia",
    flag: "./flags/cyp.svg"
  },
  {
    country: "Czech Republic",
    translatedName: "Tschechische Republik",
    capital: "Prague",
    flag: "./flags/cze.svg"
  },
  {
    country: "Denmark",
    translatedName: "Dänemark",
    capital: "Copenhagen",
    flag: "./flags/dnk.svg"
  },
  {
    country: "Djibouti",
    translatedName: "Dschibuti",
    capital: "Djibouti",
    flag: "./flags/dji.svg"
  },
  {
    country: "Dominica",
    translatedName: "Dominica",
    capital: "Roseau",
    flag: "./flags/dma.svg"
  },
  {
    country: "Dominican Republic",
    translatedName: "Dominikanische Republik",
    capital: "Santo Domingo",
    flag: "./flags/dom.svg"
  },
  {
    country: "Ecuador",
    translatedName: "Ecuador",
    capital: "Quito",
    flag: "./flags/ecu.svg"
  },
  {
    country: "Egypt",
    translatedName: "Ägypten",
    capital: "Cairo",
    flag: "./flags/egy.svg"
  },
  {
    country: "El Salvador",
    translatedName: "El Salvador",
    capital: "San Salvador",
    flag: "./flags/slv.svg"
  },
  {
    country: "Equatorial Guinea",
    translatedName: "Äquatorial-Guinea",
    capital: "Malabo",
    flag: "./flags/gnq.svg"
  },
  {
    country: "Eritrea",
    translatedName: "Eritrea",
    capital: "Asmara",
    flag: "./flags/eri.svg"
  },
  {
    country: "Estonia",
    translatedName: "Estland",
    capital: "Tallinn",
    flag: "./flags/est.svg"
  },
  {
    country: "Ethiopia",
    translatedName: "Äthiopien",
    capital: "Addis Ababa",
    flag: "./flags/eth.svg"
  },
  {
    country: "Falkland Islands",
    translatedName: "Falklandinseln",
    capital: "Stanley",
    flag: "./flags/flk.svg"
  },
  {
    country: "Faroe Islands",
    translatedName: "Färöer-Inseln",
    capital: "Torshavn",
    flag: "./flags/fro.svg"
  },
  {
    country: "Fiji",
    translatedName: "Fidschi",
    capital: "Suva",
    flag: "./flags/fji.svg"
  },
  {
    country: "Finland",
    translatedName: "Finnland",
    capital: "Helsinki",
    flag: "./flags/fin.svg"
  },
  {
    country: "France",
    translatedName: "Frankreich",
    capital: "Paris",
    flag: "./flags/fra.svg"
  },
  {
    country: "French Guiana",
    translatedName: "Französisch Guyana",
    capital: "Cayenne",
    flag: "./flags/guf.svg"
  },
  {
    country: "French Polynesia",
    translatedName: "Französisch-Polynesien",
    capital: "Papeete",
    flag: "./flags/pyf.svg"
  },
  {
    country: "French Southern and Antarctic Lands",
    translatedName: "Französische Süd- und Antarktisgebiete",
    capital: "Port-aux-Francais",
    flag: "./flags/atf.svg"
  },
  {
    country: "Gabon",
    translatedName: "Gabun",
    capital: "Libreville",
    flag: "./flags/gab.svg"
  },
  {
    country: "Gambia",
    translatedName: "Gambia",
    capital: "Banjul",
    flag: "./flags/gmb.svg"
  },
  {
    country: "Georgia",
    translatedName: "Georgien",
    capital: "Tbilisi",
    flag: "./flags/geo.svg"
  },
  {
    country: "Germany",
    translatedName: "Deutschland",
    capital: "Berlin",
    flag: "./flags/deu.svg"
  },
  {
    country: "Ghana",
    translatedName: "Ghana",
    capital: "Accra",
    flag: "./flags/gha.svg"
  },
  {
    country: "Gibraltar",
    translatedName: "Gibraltar",
    capital: "Gibraltar",
    flag: "./flags/gib.svg"
  },
  {
    country: "Greece",
    translatedName: "Griechenland",
    capital: "Athens",
    flag: "./flags/grc.svg"
  },
  {
    country: "Greenland",
    translatedName: "Grönland",
    capital: "Nuuk",
    flag: "./flags/grl.svg"
  },
  {
    country: "Grenada",
    translatedName: "Grenada",
    capital: "St. George's",
    flag: "./flags/grd.svg"
  },
  {
    country: "Guadeloupe",
    translatedName: "Guadeloupe",
    capital: "Basse-Terre",
    flag: "./flags/glp.svg"
  },
  {
    country: "Guam",
    translatedName: "Guam",
    capital: "Hagatna",
    flag: "./flags/gum.svg"
  },
  {
    country: "Guatemala",
    translatedName: "Guatemala",
    capital: "Guatemala City",
    flag: "./flags/gtm.svg"
  },
  {
    country: "Guernsey",
    translatedName: "Guernsey",
    capital: "St. Peter Port",
    flag: "./flags/ggy.svg"
  },
  {
    country: "Guinea",
    translatedName: "Guinea",
    capital: "Conakry",
    flag: "./flags/gin.svg"
  },
  {
    country: "Guinea-Bissau",
    translatedName: "Guinea-Bissau",
    capital: "Bissau",
    flag: "./flags/gnb.svg"
  },
  {
    country: "Guyana",
    translatedName: "Guyana",
    capital: "Georgetown",
    flag: "./flags/guy.svg"
  },
  {
    country: "Haiti",
    translatedName: "Haiti",
    capital: "Port-au-Prince",
    flag: "./flags/hti.svg"
  },
  {
    country: "Vatican City",
    translatedName: "Vatikanstadt",
    capital: "Vatican City",
    flag: "./flags/vat.svg"
  },
  {
    country: "Honduras",
    translatedName: "Honduras",
    capital: "Tegucigalpa",
    flag: "./flags/hnd.svg"
  },
  {
    country: "Hong Kong",
    translatedName: "Hongkong",
    capital: "City of Victoria",
    flag: "./flags/hkg.svg"
  },
  {
    country: "Hungary",
    translatedName: "Ungarn",
    capital: "Budapest",
    flag: "./flags/hun.svg"
  },
  {
    country: "Iceland",
    translatedName: "Island",
    capital: "Reykjavik",
    flag: "./flags/isl.svg"
  },
  {
    country: "India",
    translatedName: "Indien",
    capital: "New Delhi",
    flag: "./flags/ind.svg"
  },
  {
    country: "Indonesia",
    translatedName: "Indonesien",
    capital: "Jakarta",
    flag: "./flags/idn.svg"
  },
  {
    country: "Ivory Coast",
    translatedName: "Elfenbeinküste",
    capital: "Yamoussoukro",
    flag: "./flags/civ.svg"
  },
  {
    country: "Iran",
    translatedName: "Iran",
    capital: "Tehran",
    flag: "./flags/irn.svg"
  },
  {
    country: "Iraq",
    translatedName: "Irak",
    capital: "Baghdad",
    flag: "./flags/irq.svg"
  },
  {
    country: "Ireland",
    translatedName: "Irland",
    capital: "Dublin",
    flag: "./flags/irl.svg"
  },
  {
    country: "Isle of Man",
    translatedName: "Insel Man",
    capital: "Douglas",
    flag: "./flags/imn.svg"
  },
  {
    country: "Israel",
    translatedName: "Israel",
    capital: "Jerusalem",
    flag: "./flags/isr.svg"
  },
  {
    country: "Italy",
    translatedName: "Italien",
    capital: "Rome",
    flag: "./flags/ita.svg"
  },
  {
    country: "Jamaica",
    translatedName: "Jamaika",
    capital: "Kingston",
    flag: "./flags/jam.svg"
  },
  {
    country: "Japan",
    translatedName: "Japan",
    capital: "Tokyo",
    flag: "./flags/jpn.svg"
  },
  {
    country: "Jersey",
    translatedName: "Jersey",
    capital: "Saint Helier",
    flag: "./flags/jey.svg"
  },
  {
    country: "Jordan",
    translatedName: "Jordanien",
    capital: "Amman",
    flag: "./flags/jor.svg"
  },
  {
    country: "Kazakhstan",
    translatedName: "Kasachstan",
    capital: "Astana",
    flag: "./flags/kaz.svg"
  },
  {
    country: "Kenya",
    translatedName: "Kenia",
    capital: "Nairobi",
    flag: "./flags/ken.svg"
  },
  {
    country: "Kiribati",
    translatedName: "Kiribati",
    capital: "South Tarawa",
    flag: "./flags/kir.svg"
  },
  {
    country: "Kuwait",
    translatedName: "Kuwait",
    capital: "Kuwait City",
    flag: "./flags/kwt.svg"
  },
  {
    country: "Kyrgyzstan",
    translatedName: "Kirgisistan",
    capital: "Bishkek",
    flag: "./flags/kgz.svg"
  },
  {
    country: "Laos",
    translatedName: "Laos",
    capital: "Vientiane",
    flag: "./flags/lao.svg"
  },
  {
    country: "Latvia",
    translatedName: "Lettland",
    capital: "Riga",
    flag: "./flags/lva.svg"
  },
  {
    country: "Lebanon",
    translatedName: "Libanon",
    capital: "Beirut",
    flag: "./flags/lbn.svg"
  },
  {
    country: "Lesotho",
    translatedName: "Lesotho",
    capital: "Maseru",
    flag: "./flags/lso.svg"
  },
  {
    country: "Liberia",
    translatedName: "Liberia",
    capital: "Monrovia",
    flag: "./flags/lbr.svg"
  },
  {
    country: "Libya",
    translatedName: "Libyen",
    capital: "Tripoli",
    flag: "./flags/lby.svg"
  },
  {
    country: "Liechtenstein",
    translatedName: "Liechtenstein",
    capital: "Vaduz",
    flag: "./flags/lie.svg"
  },
  {
    country: "Lithuania",
    translatedName: "Litauen",
    capital: "Vilnius",
    flag: "./flags/ltu.svg"
  },
  {
    country: "Luxembourg",
    translatedName: "Luxemburg",
    capital: "Luxembourg",
    flag: "./flags/lux.svg"
  },
  {
    country: "Macedonia",
    translatedName: "Mazedonien",
    capital: "Skopje",
    flag: "./flags/mkd.svg"
  },
  {
    country: "Madagascar",
    translatedName: "Madagaskar",
    capital: "Antananarivo",
    flag: "./flags/mdg.svg"
  },
  {
    country: "Malawi",
    translatedName: "Malawi",
    capital: "Lilongwe",
    flag: "./flags/mwi.svg"
  },
  {
    country: "Malaysia",
    translatedName: "Malaysia",
    capital: "Kuala Lumpur",
    flag: "./flags/mys.svg"
  },
  {
    country: "Maldives",
    translatedName: "Malediven",
    capital: "Male",
    flag: "./flags/mdv.svg"
  },
  {
    country: "Mali",
    translatedName: "Mali",
    capital: "Bamako",
    flag: "./flags/mli.svg"
  },
  {
    country: "Malta",
    translatedName: "Malta",
    capital: "Valletta",
    flag: "./flags/mlt.svg"
  },
  {
    country: "Marshall Islands",
    translatedName: "Marshallinseln",
    capital: "Majuro",
    flag: "./flags/mhl.svg"
  },
  {
    country: "Martinique",
    translatedName: "Martinique",
    capital: "Fort-de-France",
    flag: "./flags/mtq.svg"
  },
  {
    country: "Mauritania",
    translatedName: "Mauretanien",
    capital: "Nouakchott",
    flag: "./flags/mrt.svg"
  },
  {
    country: "Mauritius",
    translatedName: "Mauritius",
    capital: "Port Louis",
    flag: "./flags/mus.svg"
  },
  {
    country: "Mayotte",
    translatedName: "Mayotte",
    capital: "Mamoudzou",
    flag: "./flags/myt.svg"
  },
  {
    country: "Mexico",
    translatedName: "Mexiko",
    capital: "Mexico City",
    flag: "./flags/mex.svg"
  },
  {
    country: "Micronesia",
    translatedName: "Mikronesien",
    capital: "Palikir",
    flag: "./flags/fsm.svg"
  },
  {
    country: "Moldova",
    translatedName: "Moldawie",
    capital: "Chisinau",
    flag: "./flags/mda.svg"
  },
  {
    country: "Monaco",
    translatedName: "Monaco",
    capital: "Monaco",
    flag: "./flags/mco.svg"
  },
  {
    country: "Mongolia",
    translatedName: "Mongolei",
    capital: "Ulan Bator",
    flag: "./flags/mng.svg"
  },
  {
    country: "Montenegro",
    translatedName: "Montenegro",
    capital: "Podgorica",
    flag: "./flags/mne.svg"
  },
  {
    country: "Montserrat",
    translatedName: "Montserrat",
    capital: "Plymouth",
    flag: "./flags/msr.svg"
  },
  {
    country: "Morocco",
    translatedName: "Marokko",
    capital: "Rabat",
    flag: "./flags/mar.svg"
  },
  {
    country: "Mozambique",
    translatedName: "Mosambik",
    capital: "Maputo",
    flag: "./flags/moz.svg"
  },
  {
    country: "Myanmar",
    translatedName: "Myanmar",
    capital: "Naypyidaw",
    flag: "./flags/mmr.svg"
  },
  {
    country: "Namibia",
    translatedName: "Namibia",
    capital: "Windhoek",
    flag: "./flags/nam.svg"
  },
  {
    country: "Nauru",
    translatedName: "Nauru",
    capital: "Yaren",
    flag: "./flags/nru.svg"
  },
  {
    country: "Nepal",
    translatedName: "Nepal",
    capital: "Kathmandu",
    flag: "./flags/npl.svg"
  },
  {
    country: "Netherlands",
    translatedName: "Niederlande",
    capital: "Amsterdam",
    flag: "./flags/nld.svg"
  },
  {
    country: "New Caledonia",
    translatedName: "Neukaledonien",
    capital: "Noumea",
    flag: "./flags/ncl.svg"
  },
  {
    country: "New Zealand",
    translatedName: "Neuseeland",
    capital: "Wellington",
    flag: "./flags/nzl.svg"
  },
  {
    country: "Nicaragua",
    translatedName: "Nicaragua",
    capital: "Managua",
    flag: "./flags/nic.svg"
  },
  {
    country: "Niger",
    translatedName: "Niger",
    capital: "Niamey",
    flag: "./flags/ner.svg"
  },
  {
    country: "Nigeria",
    translatedName: "Nigeria",
    capital: "Abuja",
    flag: "./flags/nga.svg"
  },
  {
    country: "Niue",
    translatedName: "Niue",
    capital: "Alofi",
    flag: "./flags/niu.svg"
  },
  {
    country: "Norfolk Island",
    translatedName: "Norfolkinsel",
    capital: "Kingston",
    flag: "./flags/nfk.svg"
  },
  {
    country: "North Korea",
    translatedName: "Nordkorea",
    capital: "Pyongyang",
    flag: "./flags/prk.svg"
  },
  {
    country: "Northern Mariana Islands",
    translatedName: "Nördliche Marianen",
    capital: "Saipan",
    flag: "./flags/mnp.svg"
  },
  {
    country: "Norway",
    translatedName: "Norwegen",
    capital: "Oslo",
    flag: "./flags/nor.svg"
  },
  {
    country: "Oman",
    translatedName: "Oman",
    capital: "Muscat",
    flag: "./flags/omn.svg"
  },
  {
    country: "Pakistan",
    translatedName: "Pakistan",
    capital: "Islamabad",
    flag: "./flags/pak.svg"
  },
  {
    country: "Palau",
    translatedName: "Palau",
    capital: "Ngerulmud",
    flag: "./flags/plw.svg"
  },
  {
    country: "Palestine",
    translatedName: "Palästina",
    capital: "Ramallah",
    flag: "./flags/pse.svg"
  },
  {
    country: "Panama",
    translatedName: "Panama",
    capital: "Panama City",
    flag: "./flags/pan.svg"
  },
  {
    country: "Papua New Guinea",
    translatedName: "Papua-Neuguinea",
    capital: "Port Moresby",
    flag: "./flags/png.svg"
  },
  {
    country: "Paraguay",
    translatedName: "Paraguay",
    capital: "Asuncion",
    flag: "./flags/pry.svg"
  },
  {
    country: "Peru",
    translatedName: "Peru",
    capital: "Lima",
    flag: "./flags/per.svg"
  },
  {
    country: "Philippines",
    translatedName: "Philippinen",
    capital: "Manila",
    flag: "./flags/phl.svg"
  },
  {
    country: "Pitcairn Islands",
    translatedName: "Pitcairn",
    capital: "Adamstown",
    flag: "./flags/pcn.svg"
  },
  {
    country: "Poland",
    translatedName: "Polen",
    capital: "Warsaw",
    flag: "./flags/pol.svg"
  },
  {
    country: "Portugal",
    translatedName: "Portugal",
    capital: "Lisbon",
    flag: "./flags/prt.svg"
  },
  {
    country: "Puerto Rico",
    translatedName: "Puerto Rico",
    capital: "San Juan",
    flag: "./flags/pri.svg"
  },
  {
    country: "Qatar",
    translatedName: "Katar",
    capital: "Doha",
    flag: "./flags/qat.svg"
  },
  {
    country: "Republic of Kosovo",
    translatedName: "Republic of Kosovo",
    capital: "Pristina",
    flag: "./flags/kos.svg"
  },
  {
    country: "Reunion",
    translatedName: "Reunion",
    capital: "Saint-Denis",
    flag: "./flags/reu.svg"
  },
  {
    country: "Romania",
    translatedName: "Rumänien",
    capital: "Bucharest",
    flag: "./flags/rou.svg"
  },
  {
    country: "Russia",
    translatedName: "Russland",
    capital: "Moscow",
    flag: "./flags/rus.svg"
  },
  {
    country: "Rwanda",
    translatedName: "Ruanda",
    capital: "Kigali",
    flag: "./flags/rwa.svg"
  },
  {
    country: "Saint Barthelemy",
    translatedName: "Saint-Barthelemy",
    capital: "Gustavia",
    flag: "./flags/blm.svg"
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    translatedName: "Sankt Helena",
    capital: "Jamestown",
    flag: "./flags/shn.svg"
  },
  {
    country: "Saint Kitts and Nevis",
    translatedName: "St. Kitts und Nevis",
    capital: "Basseterre",
    flag: "./flags/kna.svg"
  },
  {
    country: "Saint Lucia",
    translatedName: "Saint Lucia",
    capital: "Castries",
    flag: "./flags/lca.svg"
  },
  {
    country: "Saint Martin",
    translatedName: "Saint Martin",
    capital: "Marigot",
    flag: "./flags/maf.svg"
  },
  {
    country: "Saint Pierre and Miquelon",
    translatedName: "Saint-Pierre und Miquelon",
    capital: "Saint-Pierre",
    flag: "./flags/spm.svg"
  },
  {
    country: "Saint Vincent and the Grenadines",
    translatedName: "Saint Vincent und die Grenadinen",
    capital: "Kingstown",
    flag: "./flags/vct.svg"
  },
  {
    country: "Samoa",
    translatedName: "Samoa",
    capital: "Apia",
    flag: "./flags/wsm.svg"
  },
  {
    country: "San Marino",
    translatedName: "San Marino",
    capital: "City of San Marino",
    flag: "./flags/smr.svg"
  },
  {
    country: "Sao Tome and Principe",
    translatedName: "Sao Tome und Principe",
    capital: "Sao Tome",
    flag: "./flags/stp.svg"
  },
  {
    country: "Saudi Arabia",
    translatedName: "Saudi-Arabien",
    capital: "Riyadh",
    flag: "./flags/sau.svg"
  },
  {
    country: "Senegal",
    translatedName: "Senegal",
    capital: "Dakar",
    flag: "./flags/sen.svg"
  },
  {
    country: "Serbia",
    translatedName: "Serbien",
    capital: "Belgrade",
    flag: "./flags/srb.svg"
  },
  {
    country: "Seychelles",
    translatedName: "Seychellen",
    capital: "Victoria",
    flag: "./flags/syc.svg"
  },
  {
    country: "Sierra Leone",
    translatedName: "Sierra Leone",
    capital: "Freetown",
    flag: "./flags/sle.svg"
  },
  {
    country: "Singapore",
    translatedName: "Singapur",
    capital: "Singapore",
    flag: "./flags/sgp.svg"
  },
  {
    country: "Sint Maarten",
    translatedName: "Sint Maarten",
    capital: "Philipsburg",
    flag: "./flags/sxm.svg"
  },
  {
    country: "Slovakia",
    translatedName: "Slowakei",
    capital: "Bratislava",
    flag: "./flags/svk.svg"
  },
  {
    country: "Slovenia",
    translatedName: "Slowenien",
    capital: "Ljubljana",
    flag: "./flags/svn.svg"
  },
  {
    country: "Solomon Islands",
    translatedName: "Salomonen",
    capital: "Honiara",
    flag: "./flags/slb.svg"
  },
  {
    country: "Somalia",
    translatedName: "Somalia",
    capital: "Mogadishu",
    flag: "./flags/som.svg"
  },
  {
    country: "South Africa",
    translatedName: "Republik Südafrika",
    capital: "Pretoria",
    flag: "./flags/zaf.svg"
  },
  {
    country: "South Georgia",
    translatedName: "Südgeorgien und die Südlichen Sandwichinseln",
    capital: "King Edward Point",
    flag: "./flags/sgs.svg"
  },
  {
    country: "South Korea",
    translatedName: "Südkorea",
    capital: "Seoul",
    flag: "./flags/kor.svg"
  },
  {
    country: "South Sudan",
    translatedName: "Südsudan",
    capital: "Juba",
    flag: "./flags/ssd.svg"
  },
  {
    country: "Spain",
    translatedName: "Spanien",
    capital: "Madrid",
    flag: "./flags/esp.svg"
  },
  {
    country: "Sri Lanka",
    translatedName: "Sri Lanka",
    capital: "Colombo",
    flag: "./flags/lka.svg"
  },
  {
    country: "Sudan",
    translatedName: "Sudan",
    capital: "Khartoum",
    flag: "./flags/sdn.svg"
  },
  {
    country: "Suriname",
    translatedName: "Suriname",
    capital: "Paramaribo",
    flag: "./flags/sur.svg"
  },
  {
    country: "Svalbard and Jan Mayen",
    translatedName: "Svalbard und Jan Mayen",
    capital: "Longyearbyen",
    flag: "./flags/sjm.svg"
  },
  {
    country: "Swaziland",
    translatedName: "Swasiland",
    capital: "Lobamba",
    flag: "./flags/swz.svg"
  },
  {
    country: "Sweden",
    translatedName: "Schweden",
    capital: "Stockholm",
    flag: "./flags/swe.svg"
  },
  {
    country: "Switzerland",
    translatedName: "Schweiz",
    capital: "Bern",
    flag: "./flags/che.svg"
  },
  {
    country: "Syria",
    translatedName: "Syrien",
    capital: "Damascus",
    flag: "./flags/syr.svg"
  },
  {
    country: "Taiwan",
    translatedName: "Taiwan",
    capital: "Taipei",
    flag: "./flags/twn.svg"
  },
  {
    country: "Tajikistan",
    translatedName: "Tadschikistan",
    capital: "Dushanbe",
    flag: "./flags/tjk.svg"
  },
  {
    country: "Tanzania",
    translatedName: "Tansania",
    capital: "Dodoma",
    flag: "./flags/tza.svg"
  },
  {
    country: "Thailand",
    translatedName: "Thailand",
    capital: "Bangkok",
    flag: "./flags/tha.svg"
  },
  {
    country: "Timor-Leste",
    translatedName: "Timor-Leste",
    capital: "Dili",
    flag: "./flags/tls.svg"
  },
  {
    country: "Togo",
    translatedName: "Togo",
    capital: "Lome",
    flag: "./flags/tgo.svg"
  },
  {
    country: "Tokelau",
    translatedName: "Tokelau",
    capital: "Fakaofo",
    flag: "./flags/tkl.svg"
  },
  {
    country: "Tonga",
    translatedName: "Tonga",
    capital: "Nuku'alofa",
    flag: "./flags/ton.svg"
  },
  {
    country: "Trinidad and Tobago",
    translatedName: "Trinidad und Tobago",
    capital: "Port of Spain",
    flag: "./flags/tto.svg"
  },
  {
    country: "Tunisia",
    translatedName: "Tunesien",
    capital: "Tunis",
    flag: "./flags/tun.svg"
  },
  {
    country: "Turkey",
    translatedName: "Türkei",
    capital: "Ankara",
    flag: "./flags/tur.svg"
  },
  {
    country: "Turkmenistan",
    translatedName: "Turkmenistan",
    capital: "Ashgabat",
    flag: "./flags/tkm.svg"
  },
  {
    country: "Turks and Caicos Islands",
    translatedName: "Turks- und Caicosinseln",
    capital: "Cockburn Town",
    flag: "./flags/tca.svg"
  },
  {
    country: "Tuvalu",
    translatedName: "Tuvalu",
    capital: "Funafuti",
    flag: "./flags/tuv.svg"
  },
  {
    country: "Uganda",
    translatedName: "Uganda",
    capital: "Kampala",
    flag: "./flags/uga.svg"
  },
  {
    country: "Ukraine",
    translatedName: "Ukraine",
    capital: "Kiev",
    flag: "./flags/ukr.svg"
  },
  {
    country: "United Arab Emirates",
    translatedName: "Vereinigte Arabische Emirate",
    capital: "Abu Dhabi",
    flag: "./flags/are.svg"
  },
  {
    country: "United Kingdom",
    translatedName: "Vereinigtes Königreich",
    capital: "London",
    flag: "./flags/gbr.svg"
  },
  {
    country: "United States",
    translatedName: "Vereinigte Staaten von Amerika",
    capital: "Washington D.C.",
    flag: "./flags/usa.svg"
  },
  {
    country: "United States Virgin Islands",
    translatedName: "Amerikanische Jungferninseln",
    capital: "Charlotte Amalie",
    flag: "./flags/vir.svg"
  },
  {
    country: "Uruguay",
    translatedName: "Uruguay",
    capital: "Montevideo",
    flag: "./flags/ury.svg"
  },
  {
    country: "Uzbekistan",
    translatedName: "Usbekistan",
    capital: "Tashkent",
    flag: "./flags/uzb.svg"
  },
  {
    country: "Vanuatu",
    translatedName: "Vanuatu",
    capital: "Port Vila",
    flag: "./flags/vut.svg"
  },
  {
    country: "Venezuela",
    translatedName: "Venezuela",
    capital: "Caracas",
    flag: "./flags/ven.svg"
  },
  {
    country: "Vietnam",
    translatedName: "Vietnam",
    capital: "Hanoi",
    flag: "./flags/vnm.svg"
  },
  {
    country: "Wallis and Futuna",
    translatedName: "Wallis und Futuna",
    capital: "Mata-Utu",
    flag: "./flags/wlf.svg"
  },
  {
    country: "Western Sahara",
    translatedName: "Westsahara",
    capital: "El Aaiun",
    flag: "./flags/esh.svg"
  },
  {
    country: "Yemen",
    translatedName: "Jemen",
    capital: "Sana'a",
    flag: "./flags/yem.svg"
  },
  {
    country: "Zambia",
    translatedName: "Sambia",
    capital: "Lusaka",
    flag: "./flags/zmb.svg"
  },
  {
    country: "Zimbabwe",
    translatedName: "Simbabwe",
    capital: "Harare",
    flag: "./flags/zwe.svg"
  }
]